import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Content from '../content/Content';
import LinkButton from '../link-button/LinkButton';
import styles from './CookieNotice.module.css';

export const cookieName = 'cookieNotice';

const CookieNotice: FunctionComponent = () => {
    const [cookies, setCookie, removeCookie] = useCookies([cookieName]);

    const getExpiry = () => {
        const oneYearFromNow = new Date();
        oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
        return oneYearFromNow;
    };

    const checkExpriy = () => {
        if (cookies[cookieName] !== undefined) {
            setOurCookie();
        }
    };
    const setOurCookie = () => {
        setCookie(cookieName, true, {
            path: '/',
            expires: getExpiry(),
        });
    };

    const okClicked = () => {
        if (cookies[cookieName] !== undefined) {
            removeCookie(cookieName, {
                path: '/',
                expires: getExpiry(),
            });
        } else {
            setOurCookie();
        }
    };

    checkExpriy();
    return (
        <div>
            {cookies[cookieName] !== undefined ? null : (
                <section className={styles.root}>
                    <Content>
                        <div className={styles.content}>
                            <p>
                                We use cookies to try and improve this site. See
                                our <Link to="/cookies">cookie notice</Link> for
                                more info.
                            </p>
                            <div className={styles.button}>
                                <LinkButton isThirdly onClick={okClicked}>
                                    OK
                                </LinkButton>
                            </div>
                        </div>
                    </Content>
                </section>
            )}
        </div>
    );
};

export default CookieNotice;
