import React, { FunctionComponent } from 'react';
// import styles from './.module.css';

const Form: FunctionComponent = () => {
    console.log('Hello Form');
    return (
        <div>Hello World</div>
    );
};

export default Form;