import React, { FunctionComponent } from 'react';
import LinkButton from '../link-button/LinkButton';
import Content from '../content/Content';
import Logo from '../logo/Logo';
import styles from './PageHeader.module.css';

interface PageHeaderProps {
    helpText?: string;
    isHelpSecondary?: boolean;
    hideGettingStarted?: boolean;
}

const PageHeader: FunctionComponent<PageHeaderProps> = (
    props: PageHeaderProps
) => {
    const { hideGettingStarted, isHelpSecondary, helpText } = props;
    return (
        <header className={styles.root}>
            <Content>
                <div className={styles.content}>
                    <Logo />
                    {!hideGettingStarted && 
                        <div className={styles.end}>
                            <LinkButton href="/getstarted" isSecondary={isHelpSecondary}>
                                {helpText}
                            </LinkButton>    
                        </div>
                    }
                </div>
            </Content>
        </header>
    );
};

PageHeader.defaultProps = {
    helpText: 'Get Started',
    isHelpSecondary: false,
    hideGettingStarted: false,
};

export default PageHeader;
