import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import Content from '../content/Content';
import styles from './PageFooter.module.css';

const PageFooter: FunctionComponent = () => (
    <footer className={styles.root}>
        <Content>
            <Link to="./privacy">Privacy</Link> &nbsp;&nbsp;
            <Link to="./terms">Terms</Link> &nbsp;&nbsp;
            <Link to="./cookies">Cookies</Link>
        </Content>
    </footer>
);

export default PageFooter;
