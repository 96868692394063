import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import {
    DownloadApp,
    FeatureSets,
    Hero,
    LinkButton,
    PageHeader,
    ProductFeature,
    YoutubeVideo,
} from '../../presentational';
import imageHero from '../../resources/feature--hero.png';
import imageBusiness from '../../resources/feature--businesses.png';
import imageHeroLg from '../../resources/feature--hero-lg.png';
import imageReview from '../../resources/feature--review.png';
import imageImport from '../../resources/feature--import.png';
import imageSubmit from '../../resources/feature--submit.png';
import imagePrivacy from '../../resources/feature--privacy.png';
import imageHelp from '../../resources/feature--help.png';
import styles from './Home.module.css';

const Home: FunctionComponent = () => (
    <div>
        <Helmet>
            <title>000-VAT</title>
        </Helmet>
        <DownloadApp />
        <PageHeader />
        <Hero
            heroTitle="VAT For iPhone"
            heroSubtitleNode={<div>Tax. Made Simple.</div>}
            copyNode={
                <p>
                    For small businesses, accountants and bookkeepers. Our HMRC
                    recognised app makes reviewing and submitting VAT a breeze.
                    It&apos;s compatible with Making Tax Digital (MTD) and works
                    with your existing business spreadsheets.
                </p>
            }
            imgSrc={imageHero}
            imgSrcLg={imageHeroLg}
        />
        {/* <Hero 
        heroTitle="VAT on iPhone"
        heroSubtitleNode={
          <div> 
            Get Making Tax Digital Ready
          </div>
        }
        copyNode={
          <div> 
            HMRC's Making Tax Digital is coming on 1<sup>st</sup> April 2022. Over a million businesses will need to comply. 000-VAT  gives you everything you need for this and has been developed to work with your existing business spreadsheets.
          </div>
        }
        imgSrc={imageHero}
        imgSrcLg={imageHeroLg} /> */}
        <ProductFeature
            isInverted
            featureTitle="VAT. By Your Side."
            detail={
                <YoutubeVideo videoSrc="https://www.youtube-nocookie.com/embed/nNQnmxWZOC4" />
            }
        >
            <p>
                Living on your phone, our app is ready when you are. Managing
                VAT has never been easier. With 000-VAT you can:
            </p>
            <p />
            <ul>
                <li>Review VAT obligations</li>
                <li>Import data from spreadsheets</li>
                <li>Submit MTD Compatible VAT</li>
                <li>Add up to 100 businesses</li>
            </ul>
            <LinkButton className={styles.button} href="/getstarted">
                Get Started For <strong>FREE</strong>
            </LinkButton>
        </ProductFeature>
        <ProductFeature
            isInverted={false}
            imgSrc={imageReview}
            featureTitle="Review"
            featureSubtitle="VAT Obligations"
        >
            <ul>
                <li>
                    View upto 4 years of past Making Tax Digital obligations
                </li>
                <li>
                    View recent payments and stay up to date with your
                    liabilities to HRMC
                </li>
                <li>Easily print and share VAT obligations</li>
                <li>
                    View notes for standard, flat rate, cash and annual
                    accounting schemes
                </li>
            </ul>
            <LinkButton className={styles.button} href="/getstarted">
                Get Started For <strong>FREE</strong>
            </LinkButton>
        </ProductFeature>
        <ProductFeature
            isInverted
            imgSrc={imageImport}
            featureTitle="Import"
            featureSubtitle="From Microsoft Excel or Google Sheets"
        >
            <p>
                Under Making Tax Digital, your VAT figures can no longer be
                typed into online forms like they were previously. The main
                reason for this change is that HMRC were seeing far too many
                mistakes made in this manual process.
            </p>
            <p>
                Moving forward, HRMC will require an entirely end-to-end digital
                workflow. We help you achieve this by linking the data in your
                existing business spreadsheets (Microsoft Excel and Google
                Sheets currently supported) with HMRC via our iPhone app.
            </p>
            <LinkButton className={styles.button} href="/getstarted">
                Get Started For <strong>FREE</strong>
            </LinkButton>
        </ProductFeature>
        <ProductFeature
            isInverted={false}
            imgSrc={imageSubmit}
            featureTitle="Submit"
            featureSubtitle="Anytime. Anywhere."
        >
            <p>
                As 000-VAT runs on your phone, you are free to submit your VAT
                anytime, anywhere. Once you&apos;ve imported your figures,
                simply tap submit.
            </p>
            <LinkButton className={styles.button} href="/getstarted">
                Get Started For <strong>FREE</strong>
            </LinkButton>
        </ProductFeature>
        <ProductFeature
            isInverted
            imgSrc={imageBusiness}
            featureTitle="For Businesses"
            featureSubtitle="and Agents"
        >
            <p>
                Whether you&apos;re an individual business or an agent with many
                clients we have got you covered. 000-VAT supports upto 100
                businesses. Keeping an eye on your VAT obligations has never
                been easier.
            </p>
            <LinkButton className={styles.button} href="/getstarted">
                Get Started For <strong>FREE</strong>
            </LinkButton>
        </ProductFeature>
        <ProductFeature
            isInverted={false}
            imgSrc={imagePrivacy}
            featureTitle="Privacy"
            featureSubtitle="A Priority"
        >
            <p>
                We understand that your privacy is important. Unlike other
                Making Tax Digital solutions, we do not require you to upload
                your business spreadsheets to our servers. We only collect the
                minimum data we need to manage your VAT affairs.
            </p>
            <LinkButton className={styles.button} href="/getstarted">
                Get Started For <strong>FREE</strong>
            </LinkButton>
        </ProductFeature>
        <ProductFeature
            isInverted
            imgSrc={imageHelp}
            featureTitle="Help at Hand"
            featureSubtitle="Available In-App"
        >
            <p>
                We want your transition to the Making Tax Digital programme to
                be painless, so we&apos;ve included extensive in-app guides and
                videos to help you get going.
            </p>
            <LinkButton className={styles.button} href="/getstarted">
                Get Started For <strong>FREE</strong>
            </LinkButton>
        </ProductFeature>
        <FeatureSets />
    </div>
);

export default Home;
