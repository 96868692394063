import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import styles from './Logo.module.css';

const Logo: FunctionComponent = () => (
    <Link to="/" aria-label="Back to the homepage">
        <div className={styles.root} title="000-VAT Logo" />
    </Link>
);

export default Logo;
