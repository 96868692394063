import React, { useState, FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faVideo } from '@fortawesome/free-solid-svg-icons';
import {
    DownloadApp,
    Hero,
    LinkButton,
    PageHeader,
    ProductFeature,
    YoutubeVideo,
} from '../../presentational';

import imageHero from '../../resources/help--hero.png';
import imageHeroLg from '../../resources/help--hero-lg.png';
import imageDownloadApp from '../../resources/help--download-qr.png';
import imageDownloadSpreadsheet from '../../resources/help--download-spreadsheet.png';
import imageSetupExcel from '../../resources/help--setup-excel.png';
import imageSetupGoogle from '../../resources/help--setup-google.png';
import imageSubmit from '../../resources/help--submit.png';
import styles from './GetStarted.module.css';

const GetStarted: FunctionComponent = () => {
    const [isExcel, setIsExcel] = useState(true);

    function getEnvSuffix(): string {
        const hostname = window.location.hostname.toUpperCase();
        let result = '';

        if (hostname.startsWith('DEV') || hostname.startsWith('LOCALHOST')) {
            result = '.dev';
        } else if (hostname.startsWith('BETA')) {
            result = '.beta';
        }
        return result;
    }

    function getExcelPath(): string {
        return `/downloads/000-VAT${getEnvSuffix()}.excel.xlsx`;
    }

    function getGooglePath(): string {
        return `/downloads/000-VAT${getEnvSuffix()}.google.xlsx`;
    }

    function setExcel() {
        setIsExcel(true);
    }

    function setGoogle() {
        setIsExcel(false);
    }

    return (
        <div>
            <Helmet>
                <title>000-VAT - Get Started</title>
            </Helmet>
            <DownloadApp />
            <PageHeader hideGettingStarted />
            <Hero
                heroTitle="Getting Started"
                heroSubtitle="It Won't Take Long"
                imgSrc={imageHero}
                imgSrcLg={imageHeroLg}
            />
            <ProductFeature
                isInverted
                imgSrc={imageDownloadApp}
                featureTitle="Download"
                featureSubtitle="The App"
            >
                <p>
                    All you need to get started is to be enrolled in HMRCs{' '}
                    <a
                        href="https://www.gov.uk/government/publications/making-tax-digital/overview-of-making-tax-digital#making-tax-digital-for-vat"
                        target="blank"
                    >
                        Making Tax Digital
                    </a>{' '}
                    programme,&nbsp;
                    <a
                        href={process.env.REACT_APP_APPSTORE_LINK}
                        rel="noreferrer"
                        target="_blank"
                    >
                        download the app
                    </a>{' '}
                    (or scan the QR code) and sign in using your:
                </p>
                <ul>
                    <li>HMRC Username and Password</li>
                    <li>VAT registration number(s) (VRN) </li>
                </ul>
                <p>
                    See below for help setting up the app with your existing
                    business spreadsheets.
                </p>
                <LinkButton
                    className={styles.button}
                    href={process.env.REACT_APP_APPSTORE_LINK}
                    inNewTab
                >
                    Download App
                </LinkButton>
            </ProductFeature>
            <ProductFeature
                isInverted={false}
                featureTitle="Watch"
                featureSubtitle="The Video"
                detail={
                    isExcel ? (
                        <YoutubeVideo videoSrc="https://www.youtube-nocookie.com/embed/b4I9sbKYI00" />
                    ) : (
                        <YoutubeVideo videoSrc="https://www.youtube-nocookie.com/embed/sBB0dv2Nedw" />
                    )
                }
            >
                <p>
                    Watch a quick video guide to getting setup with 000-VAT and
                    your existing spreadsheets.
                </p>
                <LinkButton
                    className={styles.button}
                    isSecondary={!isExcel}
                    onClick={setExcel}
                >
                    <FontAwesomeIcon icon={faVideo} />
                    <strong className={styles.buttonDescription}>
                        MS Excel
                    </strong>{' '}
                    Video
                </LinkButton>
                <br />
                <LinkButton
                    className={styles.button}
                    isSecondary={isExcel}
                    onClick={setGoogle}
                >
                    <FontAwesomeIcon icon={faVideo} />
                    <strong className={styles.buttonDescription}>
                        Google Sheets
                    </strong>{' '}
                    Video
                </LinkButton>
            </ProductFeature>
            <ProductFeature
                isInverted
                imgSrc={imageDownloadSpreadsheet}
                featureTitle="Download"
                featureSubtitle="The Spreadsheet"
            >
                <p>
                    000-VAT spreadsheets provide the digital link between your
                    data and the app. We currently support Microsoft Excel and
                    Google Sheets.
                </p>
                <LinkButton
                    className={styles.button}
                    href={getExcelPath()}
                    isHyperlink
                    isSecondary={!isExcel}
                    onClick={setExcel}
                >
                    <FontAwesomeIcon icon={faCloudDownloadAlt} />
                    <strong className={styles.buttonDescription}>
                        MS Excel
                    </strong>{' '}
                    Spreadsheet
                </LinkButton>
                <br />
                <LinkButton
                    className={styles.button}
                    href={getGooglePath()}
                    isSecondary={isExcel}
                    isHyperlink
                    onClick={setGoogle}
                >
                    <FontAwesomeIcon icon={faCloudDownloadAlt} />
                    <strong className={styles.buttonDescription}>
                        Google Sheets
                    </strong>{' '}
                    Spreadsheet
                </LinkButton>
            </ProductFeature>
            {isExcel ? (
                <ProductFeature
                    isInverted={false}
                    imgSrc={imageSetupExcel}
                    featureTitle="Setup"
                    featureSubtitle="Your Spreadsheet"
                >
                    <p>
                        Open both the 000-VAT spreadsheet and the spreadsheet
                        you use for your business&apos;s finances on your
                        computer. After that:
                    </p>
                    <ol>
                        <li>
                            In the 000-VAT spreadsheet right-click (⌘-click for
                            Macs) on the <strong>VAT Entry</strong> worksheet
                            tab
                        </li>
                        <li>
                            Select <strong>Move or Copy</strong> from the menu
                        </li>
                        <li>
                            In the <strong>Move or Copy</strong> popup click on
                            the <strong>To book</strong> dropdown menu
                        </li>
                        <li>Select you existing finance spreadsheet</li>
                        <li>
                            Click <strong>OK</strong> and the 000-VAT worksheet
                            will be added to your existing finance spreadsheet
                        </li>
                    </ol>
                    <p className="quote">
                        <strong>NOTE</strong> Currently importing is only
                        available in the desktop version of Excel. That is, it
                        is not a feature of either the Web of iOS versions of
                        the Excel.
                    </p>
                </ProductFeature>
            ) : (
                <ProductFeature
                    isInverted={false}
                    imgSrc={imageSetupGoogle}
                    featureTitle="Setup"
                    featureSubtitle="Your Spreadsheet"
                >
                    <p>
                        Open the spreadsheet you use to track your{' '}
                        <strong>business&apos;s finances</strong> and:
                    </p>
                    <ol>
                        <li>
                            Open the <strong>File</strong> menu
                        </li>
                        <li>
                            Select <strong>Import</strong>
                        </li>
                        <li>
                            Select the <strong>Upload</strong> tab
                        </li>
                        <li>Upload the 000-VAT spreadsheet </li>
                        <li>
                            Click the <strong>Select</strong> button to complete
                        </li>
                    </ol>
                </ProductFeature>
            )}
            <ProductFeature
                isInverted
                imgSrc={imageSubmit}
                featureTitle="All Done"
                featureSubtitle="Next Steps"
            >
                <p>
                    Now that setup is out of the way, click below to see how to
                    submit a VAT return.
                </p>
                <LinkButton href="/submitareturn">How to Submit VAT</LinkButton>
            </ProductFeature>
        </div>
    );
};

export default GetStarted;
