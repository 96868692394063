import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Content, PageHeader } from '../../presentational';

const Terms: FunctionComponent = () => (
    <div>
        <PageHeader />
        <Content>
            <Helmet>
                <title>000 VAT - Terms</title>
            </Helmet>
            <h2>BlewPri Ltd Terms of Service</h2>

            <h3>1. Terms</h3>

            <p>
                By accessing our app, 000 VAT, you are agreeing to be bound by
                these terms of service, all applicable laws and regulations, and
                agree that you are responsible for compliance with any
                applicable local laws. If you do not agree with any of these
                terms, you are prohibited from using or accessing 000 VAT. The
                materials contained in 000 VAT are protected by applicable
                copyright and trademark law.
            </p>
            <p>
                This iOS app is provided by BlewPri Ltd (incorporated in England
                &amp; Wales; Company Number: 08119886), 1 Chailey Gardens,
                Blewbury, Didcot, OX11 9FL.
            </p>

            <h3>2. Use License</h3>

            <ol>
                <li>
                    Permission is granted to temporarily download one copy of
                    ‘000 VAT’ per device for personal or commercial viewing.
                    This is the grant of a license, not a transfer of title, and
                    under this license you may not:
                </li>
            </ol>

            <ol>
                <li>modify or copy the materials;</li>
                <li>
                    use the materials for any commercial purpose, or for any
                    public display (commercial or non-commercial);
                </li>
                <li>
                    attempt to decompile or reverse engineer any software
                    contained in 000 VAT;
                </li>
                <li>
                    remove any copyright or other proprietary notations from the
                    materials; or
                </li>
                <li>
                    transfer the materials to another person or
                    &apos;mirror&apos; the materials on any other server.
                </li>
            </ol>

            <ol>
                <li>
                    This license shall automatically terminate if you violate
                    any of these restrictions and may be terminated by BlewPri
                    Ltd at any time. Upon terminating your viewing of these
                    materials or upon the termination of this license, you must
                    destroy any downloaded materials in your possession whether
                    in electronic or printed format.
                </li>
            </ol>

            <h3>3. Disclaimer</h3>

            <ol>
                <li>
                    The materials within 000 VAT are provided on an &apos;as
                    is&apos; basis. BlewPri Ltd makes no warranties, expressed
                    or implied, and hereby disclaims and negates all other
                    warranties including, without limitation, implied warranties
                    or conditions of merchantability, fitness for a particular
                    purpose, or non-infringement of intellectual property or
                    other violation of rights.
                </li>
                <li>
                    Further, BlewPri Ltd does not warrant or make any
                    representations concerning the accuracy, likely results, or
                    reliability of the use of the materials on its website or
                    otherwise relating to such materials or on any sites linked
                    to ‘000 VAT’.
                </li>
                <li>
                    Our app and website provides some information that may help
                    you view and file your VAT obligations. This does not
                    constitute advice tailored to your specific circumstances.
                    We do not accept responsibility for users acting on or
                    refraining from acting on any such information.
                </li>
                <li>
                    We are not appointed to act on your behalf with any UK
                    government authorities, so can cannot take responsibility
                    for you not meeting any of your obligations, nor the
                    information contained within any filed documents with the
                    authorities.
                </li>
                <li>
                    It is your responsibility to meet HM Revenue &amp; Customs’
                    filing deadlines by using our software correctly and
                    entering all data accurately.
                </li>
                <li>
                    If any such term of this document is found to be wrong or
                    incomplete in any way, it does not invalidate the rest of
                    the terms which are still applicable.
                </li>
            </ol>

            <p>&nbsp;</p>

            <h3>4. Limitations</h3>

            <p>
                In no event shall BlewPri Ltd or its suppliers be liable for any
                damages (including, without limitation, damages for loss of data
                or profit, or due to business interruption) arising out of the
                use or inability to use 000 VAT, even if BlewPri Ltd or a
                BlewPri Ltd authorised representative has been notified orally
                or in writing of the possibility of such damage. Because some
                jurisdictions do not allow limitations on implied warranties, or
                limitations of liability for consequential or incidental
                damages, these limitations may not apply to you.
            </p>

            <h3>5. Accuracy of materials</h3>

            <p>
                The materials appearing in 000 VAT could include technical,
                typographical, or photographic errors. BlewPri Ltd does not
                warrant that any of the materials on 000 VAT are accurate,
                complete or current. BlewPri Ltd may make changes to the
                materials contained in 000 VAT at any time without notice.
            </p>

            <h3>6. Links</h3>

            <p>
                BlewPri Ltd has not reviewed all of the sites linked to its app
                and is not responsible for the contents of any such linked site.
                The inclusion of any link does not imply endorsement by BlewPri
                Ltd of the site. Use of any such linked website is at the
                user&apos;s own risk.
            </p>

            <h3>7. Modifications</h3>

            <p>
                BlewPri Ltd may revise these terms of service for its app at any
                time without notice. By using 000 VAT you are agreeing to be
                bound by the then current version of these terms of service.
            </p>

            <h3>8. Governing Law</h3>

            <p>
                These terms and conditions are governed by and construed in
                accordance with the laws of United Kingdom and you irrevocably
                submit to the exclusive jurisdiction of the courts in that State
                or location.
            </p>
            <p>&nbsp;</p>

            <h3>9. In App Purchases</h3>

            <p>
                In App purchases are purchased from and billed by Apple, not
                BlewPri Ltd. These purchases are subject to the terms and
                conditions of Apple’s App Store. All billing and refund
                enquiries must be directed towards Apple. BlewPri Ltd does not
                have access to Apple’s accounts or transactions. If you wish to
                confirm the type of subscription that has been purchased from
                Apple, please consult your Apple account or receipt as each
                subscription will have a unique billing name.
            </p>
            <p>
                Subscriptions are valid for the periods indicated in the App and
                will be re-billed until cancelled by the user. Apple will send
                an email in advance of renewal containing a hyperlink to manage
                the subscription. Apple subscriptions can be managed from a
                mobile device of computer that uses the same Apple ID as the
                initial purchase. BlewPri Ltd cannot manage Apple subscriptions.
            </p>

            <h3>9. Support</h3>

            <p>
                You must get in touch with us if you have any problem using our
                software. This must be at least 48 hours before the deadline
                Mon-Fri, excluding the periods where support is not available
                (see below).
            </p>
            <p>
                Support is not available on all British Bank Holidays, and from
                Christmas Eve to the first working day of January.
            </p>
            <p>10. Privacy</p>
            <p>
                Your privacy is important to BlewPri Ltd. Please see our website
                for our dedicated Data Privacy Policy.
            </p>
            <p>&nbsp;</p>
        </Content>
    </div>
);

export default Terms;
