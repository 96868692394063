import React, { FunctionComponent } from 'react';
import imageAppStore from '../../resources/appStore.svg';
import Content from '../content/Content';
import styles from './DownloadApp.module.css';

const DownloadApp: FunctionComponent = () => (
    <section className={styles.root}>
        <a
            href={process.env.REACT_APP_APPSTORE_LINK}
            rel="noreferrer"
            target="_blank"
        >
            <Content>
                <div className={styles.main}>
                    <div>
                        <h1 className={styles.title}>
                            <strong>000-VAT</strong> for  iPhone
                        </h1>
                        <p className={styles.description}>
                            Find it in the App Store
                        </p>
                    </div>
                    <img
                        src={imageAppStore}
                        alt="Download on the App Store"
                        className={styles.appStore}
                    />
                </div>
            </Content>
        </a>
    </section>
);

export default DownloadApp;
