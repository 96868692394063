import React, { FunctionComponent } from 'react';
import QRCode from 'qrcode.react';
import { Helmet } from 'react-helmet';
import { PageHeader, ProductFeature, ShareImport } from '../../presentational';
import styles from './Import.module.css';

const Import: FunctionComponent = () => {
    const location = `${window.location.toString()}&method=qr`;
    const qrColor = '#F5F5F5';
    return (
        <div>
            <Helmet>
                <title>Import VAT Data</title>
                <meta property="og:title" content="Import VAT Data" />
                <meta
                    property="og:description"
                    content="Import VAT data into the 000-VAT iPhone App"
                />
            </Helmet>
            {/* <DownloadApp /> */}
            <PageHeader helpText="Need help ?" isHelpSecondary />
            <ProductFeature
                isInverted={false}
                featureTitle="Import Ready"
                featureSubtitle="Grab your phone"
                detail={
                    <div className={styles.qrCodeWrapper}>
                        <div className={styles.qrCode}>
                            <QRCode
                                size={160}
                                value={location}
                                bgColor={qrColor}
                            />
                        </div>
                    </div>
                }
            >
                <ol>
                    <li>
                        Open the <strong>000-VAT</strong> app on your iPhone
                    </li>
                    <li>
                        In the app, select the VAT Obligation you want to import
                        to
                    </li>
                    <li>
                        Click <strong>Import</strong> (top right on the VAT
                        Obligation Screen)
                    </li>
                    <li>
                        Select the <strong>Import from QR Code</strong> option
                        in menu that appears
                    </li>
                    <li>
                        Scan the <strong>QR code </strong>
                    </li>
                </ol>
                <ShareImport />
            </ProductFeature>
        </div>
    );
};

export default Import;
