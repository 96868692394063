import React, { FunctionComponent } from 'react';
import Content from '../content/Content';
import styles from './ProductFeature.module.css';

export interface ProducFeatureProps {
    isInverted: boolean;
    imgSrc?: string;
    featureTitle: string;
    featureSubtitle?: string;
    children: React.ReactNode;
    detail?: React.ReactNode;
}

const ProductFeature: FunctionComponent<ProducFeatureProps> = (
    props: ProducFeatureProps
) => {
    const {
        imgSrc,
        isInverted,
        featureTitle,
        featureSubtitle,
        children,
        detail
    } = props;
    return (
        <section className={`${styles.root} ${isInverted ? styles.inverted : ''}`}>
            <Content>
                <div className={styles.content}>
                    <div className={styles.image}>
                        {imgSrc ?
                            <img src={imgSrc} alt="" /> :
                            detail
                        }
                    </div>
                    <div className={styles.copyWrapper}>
                        <div className={styles.copy}>
                            <h1 className={styles.title}>{featureTitle}</h1>
                            <h2 className={styles.subtitle}>
                                {featureSubtitle}
                            </h2>
                            <div className={styles.description}>{children}</div>
                        </div>
                    </div>
                </div>
            </Content>
        </section>
    );
};

export default ProductFeature;
