import { createAsyncThunk } from '@reduxjs/toolkit'
import { Business } from './businesses.model';

export const yo = 'dasd';

interface MyError {
    message: String
}

export const fetchBusinessesThunk = createAsyncThunk<Business[], void>(
    'fetchBusinesses',
    async (arg, thunkApi) => {
        const response = await fetch(`/data/businesses.json`);
        if (response.status !== 200) {
            return thunkApi.rejectWithValue(({ message: 'Could not load businesses'} as MyError));
        } 
        return (await response.json()) as Business[];
    }
);  