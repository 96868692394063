import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import styles from './LinkButton.module.css';

export interface LinkButtonProps {
    href?: string;
    isSecondary?: boolean;
    isThirdly?: boolean;
    inNewTab?: boolean;
    isHyperlink?: boolean;
    onClick?: () => void;
    children: React.ReactNode;
    className?: string;
}

const LinkButton: FunctionComponent<LinkButtonProps> = (
    props: LinkButtonProps
) => {
    let button;
    if (props.href) {
        if (props.inNewTab) {
            button = (
                <a
                    href={props.href}
                    onClick={props.onClick}
                    rel="noreferrer"
                    target="_blank"
                    className={`${props.className} ${styles.root} ${
                        props.isSecondary ? styles.isSecondary : ''
                    }`}
                >
                    {props.children}
                </a>
            );
        } else if (props.isHyperlink) {
            button = (
                <a
                    href={props.href}
                    onClick={props.onClick}
                    className={`${props.className} ${styles.root} ${
                        props.isSecondary ? styles.isSecondary : ''
                    }`}
                >
                    {props.children}
                </a>
            );
        } else {
            button = (
                <Link
                    to={props.href}
                    onClick={props.onClick}
                    className={`${props.className} ${styles.root} ${
                        props.isSecondary ? styles.isSecondary : ''
                    }`}
                >
                    {props.children}
                </Link>
            );
        }
    } else {
        button = (
            <div
                role="button"
                tabIndex={0}
                onKeyDown={props.onClick}
                onClick={props.onClick}
                className={`${props.className} ${styles.root} ${
                    props.isSecondary ? styles.isSecondary : ''
                } ${props.isThirdly ? styles.isThirdly : ''} `}
            >
                {props.children}
            </div>
        );
    }
    return button;
};

export default LinkButton;
