import React, { FunctionComponent } from 'react';
import styles from './Hero.module.css';

export interface HeroProps {
    heroTitle: string;
    heroSubtitle?: string;
    heroSubtitleNode?: React.ReactNode;
    copyNode?: React.ReactNode;
    imgSrc: string;
    imgSrcLg: string;
}

const Hero: FunctionComponent<HeroProps> = (props: HeroProps) => {
    const {
        copyNode,
        heroSubtitle,
        heroSubtitleNode,
        heroTitle,
        imgSrc,
        imgSrcLg,
    } = props;
    return (
        <section className={styles.root}>
            <h1 className={styles.title}>{heroTitle}</h1>
            <h2 className={styles.subtitle}>
                {heroSubtitle}
                {heroSubtitleNode}
            </h2>
            <div className={styles.copy}>
                {copyNode && 
                    <div>{copyNode}</div>
                }
            </div>
            <picture>
                <source srcSet={imgSrcLg} media="(min-width: 1000px)" />
                <img className={styles.image} alt="" src={imgSrc} />
            </picture>
        </section>
    );
};

export default Hero;
