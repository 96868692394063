import React, { FunctionComponent, useState } from 'react';
import useClipboard from 'react-use-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import styles from './ShareImport.module.css';

const ShareImport: FunctionComponent = () => {
    const [url] = useState(encodeURIComponent(window.location.toString()));
    const [, setCopied ] = useClipboard(window.location.toString());
    return (
        <section className={styles.wrapper}>
            <p>
                Alternatively, click below to send a hyperlink with this import
                to your iPhone by <strong>email</strong>, or{' '}
                <strong>copy</strong> it to your choosen messaging app.
            </p>
            <div className={styles.root}>
                <a href={`mailto:?body=${url}`} aria-label='Share via Email'>
                    <FontAwesomeIcon icon={faEnvelope} />
                </a>
                <button onClick={setCopied} type="button">
                    <div>
                        <FontAwesomeIcon icon={faCopy} />
                    </div>
                </button>
            </div>
        </section>
    );
};

export default ShareImport;



