import React, { FunctionComponent } from 'react';
import FeatureSet from '../feature-set/FeatureSet';
import LinkButton from '../link-button/LinkButton';
import Content from '../content/Content';
import styles from './FeatureSets.module.css';

const FeatureSets: FunctionComponent = () => (
    <section className={styles.wrapper}>
        <Content>
            <h1 className={styles.title}>Pricing</h1>
            <div className={styles.root}>
                <FeatureSet featureTitle="Basic">
                    <div>
                        <div>
                            <strong>Basic features</strong>:
                        </div>
                        <ul>
                            <li>Review up to 4 years of VAT Obligations</li>
                            <li>Review recent payments to HMRC</li>
                            <li>Add up to 5 businesses</li>
                        </ul>
                    </div>
                </FeatureSet>
                <FeatureSet featureTitle="Pro" price="£2.49">
                    <div>
                        <strong>Everying in Basic, plus</strong>:
                    </div>
                    <ul>
                        <li>Submit VAT to HMRC</li>
                        <li>Add up to 100 businesses</li>
                    </ul>
                </FeatureSet>
            </div>
            <div className={styles.getStarted}>
                <LinkButton href="/getstarted">
                    Get Started For <strong>FREE</strong>
                </LinkButton>
            </div>
        </Content>
    </section>
);

export default FeatureSets;
