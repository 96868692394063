import React, { FunctionComponent } from 'react';
import styles from './FeatureSet.module.css';

export interface FeatureSetProps {
    featureTitle: string;
    children?: React.ReactNode;
    priceOffer?: string;
    price?: string;
    priceFrequency?: string;
}

const FeatureSet: FunctionComponent<FeatureSetProps> = (
    props: FeatureSetProps
) => {
    const { children, featureTitle, price } = props;
    return (
        <section className={styles.root}>
            <h1 className={styles.title}>{featureTitle}</h1>
            {price ? 
                <div className={styles.pricing}>
                    <div className={styles.offer}>
                        1 Month<strong> FREE</strong>, then
                    </div>
                    <div className={styles.price}>{price}</div>
                    <div className={styles.frequency}>Per Month</div>
                </div> : 
                <div className={styles.pricing}>
                    <div className={styles.offer}>&nbsp;&nbsp;</div>
                    <div className={styles.price}>FREE</div>
                    <div className={styles.frequency}>&nbsp;</div>
                </div>
            }
            <div className={styles.features}>{children}</div>
        </section>
    );
};

export default FeatureSet;
