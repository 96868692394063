import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Content, PageHeader } from '../../presentational';

const Privacy: FunctionComponent = () => (
    <div>
        <PageHeader />
        <Content>
            <div>
                <Helmet>
                    <title>000 VAT - Privacy</title>
                </Helmet>

                <h2>Privacy Policy</h2>

                <p>
                    This privacy policy will explain how BlewPri Ltd (&apos;Our
                    Company&apos;) uses the personal data we collect from you
                    when you use our app and accompanying website.
                </p>

                <p>Topics covered:</p>

                <ul>
                    <li>What data do we collect?</li>
                    <li>How do we collect your data?</li>
                    <li>How will we use your data?</li>
                    <li>How do we store your data?</li>
                    <li>What are your data protection rights?</li>
                    <li>What are cookies?</li>
                    <li>How do we use cookies?</li>
                    <li>What types of cookies do we use?</li>
                    <li>How to manage your cookies</li>
                    <li>Privacy policies of other websites</li>
                    <li>Changes to our privacy policy</li>
                    <li>How to contact us?</li>
                </ul>

                <section>
                    <h3>What data do we collect?</h3>

                    <p>Our Company collects the following data:</p>

                    <ul>
                        <li>
                            Apples{' '}
                            <a href="https://developer.apple.com/documentation/uikit/uidevice/1620059-identifierforvendor">
                                App Identifier for Vendor
                            </a>
                        </li>
                        <li>Your current Timezone</li>
                        <li>Your device&apos;s IP address</li>
                        <li>Your device&apos;s Screen Size</li>
                        <li>Your device&apos;s Operating System + Version</li>
                        <li>Your device&apos;s Model/Name</li>
                        <li>
                            The Version of the App you are running (major +
                            minor + patch)
                        </li>
                        <li>
                            A unique identifier generated when you first use the
                            App
                        </li>
                        <li>
                            Your activity within the app (data such as your VAT
                            Registration Number is redacted before it is sent to
                            our logging platform)
                        </li>
                        <li>Time and Date of various app actions</li>
                        <li>HMRC VAT Boxes 1-9 (again VRN is redacted)</li>
                        <li>Data Import Method (eg. QR Code or Link)</li>
                        <li>Data Import Source (eg. Excel or Google Sheets)</li>
                    </ul>

                    <h4>Device Data</h4>

                    <p>
                        Our app requests access to your device&apos;s camera. We
                        do not ask for, or require access to your photos. The
                        only reason we ask for camera access for scanning QR
                        codes (used to import VAT data).
                    </p>
                    <p>
                        We may also use in-app notifications to inform you about
                        relevant upcoming events (eg. HMRC systems downtime,
                        upcoming changes to VAT MTD).
                    </p>

                    <h4>Log Data</h4>

                    <p>
                        The app uses 3rd party logging provider{' '}
                        <a href="https://github.com/SwiftyBeaver/SwiftyBeaver">
                            SwiftyBeaver
                        </a>{' '}
                        to log the above information for the purposes of
                        troubleshooting. For instance, when you encounter
                        certain errors whilst using the app, we automatically
                        collect data about the error and the circumstances
                        surrounding its occurrence.
                    </p>

                    <h4>Analytics</h4>

                    <p>
                        The app uses Firebase Analytics solely for the purposes
                        of improving the app.
                    </p>

                    <h4>HMRC Anti-Fraud Headers/Data</h4>

                    <p>
                        The application provides anti-fraud http headers to HMRC
                        (an prerequisite of using their API). More information
                        on this can be found&nbsp;
                        <a href="https://developer.service.hmrc.gov.uk/guides/fraud-prevention/connection-method/mobile-app-direct/">
                            here
                        </a>
                        .
                    </p>
                </section>

                <section>
                    <h3>How do we collect your data?</h3>

                    <p>We collect data and process data when you:</p>

                    <ul>
                        <li>Setup and use our App on your device</li>
                        <li>
                            Export VAT Boxes 1-9 from your data source (usually,
                            but not always, a spreadsheet) to our website
                        </li>
                        <li>Import VAT Boxes 1-9 to our iPhone app</li>
                        <li>
                            Voluntarily complete a customer survey or provide
                            feedback on any of our message boards or via email
                        </li>
                        <li>
                            Use or view our website via your browser’s cookies
                        </li>
                    </ul>
                </section>

                <section>
                    <h3>How will we use your data?</h3>

                    <p>Our Company collects your data:</p>

                    <ul>
                        <li>
                            For technical assessment, including to operate and
                            improve our app, associated applications and
                            associated social media platforms
                        </li>
                        <li>
                            To provide you with our app and platform&apos;s core
                            features
                        </li>
                        <li>
                            To comply with our legal obligations and resolve any
                            disputes that we may have
                        </li>
                    </ul>
                </section>

                <section>
                    <h3>How do we store your data?</h3>

                    <p>
                        All data from HRMC is stored on your device and synced
                        to your iCloud account. Aside from certain redacted data
                        mentioned above, BlewPri Ltd does not keep copies of
                        your core VAT records. Please note that a maximum of 4
                        years VAT data may be stored in the app at any one time.
                    </p>

                    <h4>Logging</h4>

                    <p>
                        SwiftyBeaver have the appropriate controls and measures
                        in place to comply with GDPR. It also meets Apple’s
                        latest requirements on the privacy of 3rd party
                        frameworks. All logging data is end-to-end encrypted
                        with symmetric AES256CBC encryption. Logged data is
                        discarded after 7-14 days.
                    </p>

                    <h4>Analytics</h4>

                    <p>
                        More information about Google’s privacy and security can
                        be found{' '}
                        <a href="https://firebase.google.com/support/privacy">
                            here
                        </a>
                        . Google Analytics retains certain identifier associated
                        data (eg. Apples Identifier for Vendors) for 60 days,
                        and retains aggregate reporting without automatic
                        expiration. Retention of user-level data, including
                        conversion, is fixed at up to 14 months.
                    </p>

                    <p>
                        With all this in mind, we advise that no method of
                        electronic transmission or storage is 100% secure and
                        cannot guarantee absolute data security. If necessary,
                        we may retain your personal information for the purposes
                        of compliance with legal requirements.
                    </p>
                </section>

                <section>
                    <h3>What are your data protection rights?</h3>

                    <p>
                        Our Company would like to make sure you are fully aware
                        of all of your data protection rights. Every user is
                        entitled to the following:
                    </p>

                    <ul>
                        <li>
                            <strong>The right to access</strong> – You have the
                            right to request Our Company for copies of your
                            personal data. We may charge you a small fee for
                            this service
                        </li>
                        <li>
                            <strong>The right to rectification</strong> – You
                            have the right to request that Our Company correct
                            any information you believe is inaccurate. You also
                            have the right to request Our Company to complete
                            the information you believe is incomplete
                        </li>
                        <li>
                            <strong>The right to erasure</strong> – You have the
                            right to request that Our Company erase your
                            personal data, under certain conditions
                        </li>
                        <li>
                            <strong>The right to restrict processing</strong> –
                            You have the right to request that Our Company
                            restrict the processing of your personal data, under
                            certain conditions
                        </li>
                        <li>
                            <strong>The right to object to processing</strong> –
                            You have the right to object to Our Company’s
                            processing of your personal data, under certain
                            conditions
                        </li>
                        <li>
                            <strong>The right to data portability</strong> – You
                            have the right to request that Our Company transfer
                            the data that we have collected to another
                            organization, or directly to you, under certain
                            conditions
                        </li>
                    </ul>

                    <p>
                        If you make a request, we have one month to respond to
                        you. If you would like to exercise any of these rights,
                        please contact us at our email:&nbsp;
                        <a href="mailto:data@blewpri.co.uk">
                            data@blewpri.co.uk
                        </a>
                    </p>
                </section>

                <section>
                    <h3>Cookies</h3>

                    <p>
                        Cookies are text files placed on your computer to
                        collect standard Internet log information and visitor
                        behavior information. When you visit our websites, we
                        may collect information from you automatically through
                        cookies or similar technology. For further information,
                        visit{' '}
                        <a href="http://allaboutcookies.org">
                            allaboutcookies.org
                        </a>
                        .
                    </p>
                </section>

                <section>
                    <h3>How do we use cookies?</h3>

                    <p>
                        Our Company uses cookies in a range of ways to improve
                        your experience on our website, and to assist in
                        troubleshooting.
                    </p>
                </section>

                <section>
                    <h3>What types of cookies do we use?</h3>

                    <p>
                        There are a number of different types of cookies,
                        however, our website uses just those that are required
                        by Firebase Analytics. See how Google uses data{' '}
                        <a href="http://www.google.com/policies/privacy/partners/">
                            here
                        </a>
                        .
                    </p>
                </section>

                <section>
                    <h3>How to manage cookies?</h3>

                    <p>
                        You can set your browser not to accept cookies, and the
                        above website tells you how to remove cookies from your
                        browser. However, in a few cases, some of our website
                        features may not function as a result.
                    </p>
                </section>

                <section>
                    <h3>Privacy policies of other websites</h3>

                    <p>
                        The Our Company website contains links to other
                        websites. Our privacy policy applies only to our
                        website, so if you click on a link to another website,
                        you should read their privacy policy.
                    </p>
                </section>

                <section>
                    <h3>Changes to our privacy policy</h3>

                    <p>
                        At our discretion, we may change our privacy policy to
                        reflect current acceptable practices. We will take
                        reasonable steps to let users know about changes via our
                        app or app delivery service. Your continued use of our
                        app after any changes to this policy will be regarded as
                        acceptance of our practices around privacy and personal
                        information.
                    </p>

                    <p>
                        If we make a significant change to this privacy policy,
                        for example changing a lawful basis on which we process
                        your personal information, we will ask you to re-consent
                        to the amended privacy policy.
                    </p>
                </section>

                <section>
                    <h3>How to contact us</h3>
                    <p>
                        If you have any questions about Our Company’s privacy
                        policy, the data we hold on you, or you would like to
                        exercise one of your data protection rights, please do
                        not hesitate to contact us at:{' '}
                        <a href="mailto:data@blewpri.co.uk">
                            data@blewpri.co.uk
                        </a>
                        .
                    </p>
                    <p>
                        You can also write to us at: BlewPri Ltd, 1 Chailey
                        Gardens, Blewbury, OX11 9FL
                    </p>
                </section>

                <section>
                    <h3>Data Controller: BlewPri Ltd</h3>
                    <p>
                        Contact:{' '}
                        <a href="data@blewpri.co.uk">data@blewpri.co.uk</a>
                    </p>
                    <p>This policy is effective as of 25 Jan 2021</p>
                </section>
                <section>
                    <h3>How to contact the appropriate authority</h3>
                    <p>
                        Should you wish to report a complaint or if you feel
                        that Our Company has not addressed your concern in a
                        satisfactory manner, you may contact the{' '}
                        <a href="https://ico.org.uk/global/contact-us/">
                            Information Commissioner’s Office
                        </a>
                        .
                    </p>
                </section>
            </div>
        </Content>
    </div>
);

export default Privacy;
