import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Hero, PageHeader, ProductFeature } from '../../presentational';

import imageHero from '../../resources/help--hero.png';
import imageHeroLg from '../../resources/help--hero-lg.png';
import imageAddFigures from '../../resources/help--add-figures.png';
import imageExport from '../../resources/help--export.png';
import imageImport from '../../resources/help--import.png';
import imageSubmit from '../../resources/help--submit.png';

const SubmitAReturn: FunctionComponent = () => (
    <div>
        <Helmet>
            <title>000 VAT - Submit A Return</title>
        </Helmet>
        <PageHeader />
        <Hero
            heroTitle="How To"
            heroSubtitle="Submit VAT "
            imgSrc={imageHero}
            imgSrcLg={imageHeroLg}
        />
        <ProductFeature
            isInverted={false}
            imgSrc={imageAddFigures}
            featureTitle="1. Add VAT Figures"
            featureSubtitle="For HMRC Boxes 1-9"
        >
            <p>Next, we&apos;ll cover how to complete a VAT return. </p>
            <ol>
                <li>
                    Click on a cell you want to add a value to (eg. Box 1) in
                    the <strong>VAT Entry</strong> worksheet, and type{' '}
                    <strong>=</strong> (the equals sign)
                </li>
                <li>
                    Switch to the worksheet you want to reference a value in
                    (eg. &apos;Company Accounts&apos; in our example) and click
                    the cell you want to select.
                </li>
                <li>
                    Press <strong>Enter</strong> and you will see the referenced
                    value in your <strong>VAT Entry</strong> worksheet
                </li>
                <li>
                    Repeat steps 1-3 for boxes 1-9 in the{' '}
                    <strong>VAT Entry</strong> worksheet. If you don&apos;t have
                    a value for any particular field, simply add a{' '}
                    <strong>0</strong> (Zero).
                </li>
            </ol>
        </ProductFeature>
        <ProductFeature
            isInverted
            imgSrc={imageExport}
            featureTitle="2. Export VAT"
            featureSubtitle="From Spreadsheet"
        >
            <p>
                Now that you&apos;ve completed BOXES 1-9, we will send them to
                the <strong>000 VAT</strong> iPhone app:
            </p>
            <ol>
                <li>
                    In the <strong>VAT Entry</strong> worksheet click{' '}
                    <strong>Prepare</strong>
                </li>
                <li>
                    Next click <strong>Export</strong>
                </li>
                <li>
                    This will turn your VAT figures into a{' '}
                    <strong>QR code</strong>
                </li>
                <li>
                    Open the 000-VAT app, open the VAT obligation your want to
                    import to, and tap the <strong>Import</strong> button.
                </li>
            </ol>
            <p className="quote">
                <strong>PRIVACY NOTICE</strong> To scan QR codes on your phone
                the app needs access to the camera. Please note that this does
                not require access to any of your photos.
            </p>
        </ProductFeature>
        <ProductFeature
            isInverted={false}
            imgSrc={imageImport}
            featureTitle="3. Import VAT"
            featureSubtitle="To the App"
        >
            <p>
                After clicking <strong>Import</strong> in the previous step:
            </p>
            <ol>
                <li>
                    Select <strong>Import from QR Code</strong>
                </li>
                <li>
                    Scan the <strong>QR Code</strong> we <br />
                    generated in the previous step
                </li>
                <li>
                    Check the <strong>VAT period</strong> is correct
                </li>
                <li>
                    Check the figures for VAT boxes 1-9 <br />
                    are correct, then tap <strong>Import</strong>
                </li>
            </ol>
            <p>
                <strong>Alternatively</strong>, if you have either the iOS
                version of <strong>Excel</strong> or{' '}
                <strong>Google Sheets</strong> installed on your phone you can
                open your spreadsheet in either and click the{' '}
                <strong>Export</strong> button (to bypass the QR code step).
            </p>
            {/* <p className="quote">
                    <strong>NOTE</strong> Importing your VAT figures is an entirely different process to submission. When importing no data is sent to HMRC. Prior to submission, you may re-import your VAT figures as many times as you need.
                </p> */}
        </ProductFeature>
        <ProductFeature
            isInverted
            imgSrc={imageSubmit}
            featureTitle="4. Submit"
            featureSubtitle="To HMRC"
        >
            <p>With your current VAT obligation open:</p>
            <ol>
                <li>
                    Review your box 1-9 figures, then tap{' '}
                    <strong>Submit</strong> when you&apos;re satisfied
                    everything is correct
                </li>
                <li>
                    Read the confirmation message, and click{' '}
                    <strong>I understand</strong> if wish to proceed
                </li>
                <li>
                    The VAT Obligation will now be submiited to HRMC. Wait for
                    this to complete to see if there are any issues you need to
                    address.{' '}
                </li>
            </ol>
            {/* <p className="quote">
                    <strong>PAYMENT NOTE</strong> There is currently no way for <strong>000 VAT</strong>
                    to send payments to HMRC, so all payments must be made using existing channels. See <a href="https://www.gov.uk/pay-vat" target="blank">HMRCs guidance</a> for the latest details on how to make payments.
                </p> */}
        </ProductFeature>
    </div>
);

export default SubmitAReturn;
