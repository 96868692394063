import { configureStore } from '@reduxjs/toolkit'
import { businessesSlice } from './slices/businesses/businesses.slice';

export const store = configureStore({
    reducer: {
        businesses: businessesSlice.reducer
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;