import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Content, PageHeader } from '../../presentational';

const Cookies: FunctionComponent = () => (
    <div>
        <Helmet>
            <title>000-VAT Cookies</title>
        </Helmet>
        <PageHeader />
        <Content>
            <h2>Cookies</h2>
            <p>
                The 000-VAT website puts small files (known as
                &apos;Cookies&apos;) onto your device to collect information
                about how you browse the site.
            </p>
            <p>Cookies are used to:</p>
            <ul>
                <li>
                    measure how you use the website so it can be updated and
                    improved based on your needs
                </li>
                <li>
                    remember the notifications you’ve seen so that we don’t show
                    them to you again
                </li>
            </ul>
            <p>
                You can find out more about how to manage cookies, including how
                to delete and block them, on the{' '}
                <a
                    href="https://ico.org.uk/for-the-public/online/cookies/"
                    rel="noreferrer"
                    target="_blank"
                >
                    Information Commission Officer’s (ICO) website
                </a>
                .
            </p>

            <h2>Measuring Website Usage (Google Analytics)</h2>
            <p>
                We use Google Analytics software to collect information about
                how you use this site. We do this to help make sure the site is
                meeting the needs of its users and to help us make improvements.
            </p>

            <p>Google Analytics stores information about:</p>
            <ul>
                <li>the pages you visit on the site</li>
                <li>how long you spend on each page</li>
                <li>how you got to the site</li>
                <li>what you click on while you’re visiting the site</li>
            </ul>

            <p>
                It doesn’t collect or store your personal information (e.g. your
                name or address) so this information can’t be used to identify
                who you are. We don’t allow Google to use or share our analytics
                data.
            </p>

            <p>Google Analytics sets the following cookies:</p>

            <table>
                <tbody>
                    <tr>
                        <th>Name</th>
                        <th>Expires</th>
                    </tr>
                    <tr>
                        <td>_ga</td>
                        <td>2 years</td>
                    </tr>
                    <tr>
                        <td>_gat</td>
                        <td>10 minutes</td>
                    </tr>
                    <tr>
                        <td>_gid</td>
                        <td>24 hours</td>
                    </tr>
                </tbody>
            </table>

            <p>
                You may{' '}
                <a href="https://tools.google.com/dlpage/gaoptout">
                    opt out of Google Analytics cookies
                </a>
                .
            </p>

            <h2>Our introductory cookie message</h2>

            <p>
                When you first visit this website you should see a pop-up
                message to inform you that the site uses cookies and to request
                your permission to set analytical cookies. We’ll store a cookie
                so that your computer knows you’ve seen it and knows not to show
                it again.
            </p>
            <table>
                <tbody>
                    <tr>
                        <th>Name</th>
                        <th>Expires</th>
                    </tr>
                    <tr>
                        <td>CookieControl</td>
                        <td>3 months</td>
                    </tr>
                </tbody>
            </table>

            <h3>YouTube videos</h3>
            <p>
                We embed videos from our official YouTube channel using
                YouTube’s privacy-enhanced mode. This mode may set cookies on
                your computer once you click on the YouTube video player, but
                YouTube will not store personally-identifiable cookie
                information for playbacks of embedded videos using the
                privacy-enhanced mode.
            </p>

            <table>
                <tbody>
                    <tr>
                        <th>Name</th>
                        <th>Expires</th>
                    </tr>
                    <tr>
                        <td>GPS</td>
                        <td>30 minutes</td>
                    </tr>
                    <tr>
                        <td>PREF</td>
                        <td>2 years</td>
                    </tr>
                    <tr>
                        <td>VISITOR_INFO1_LIVE</td>
                        <td>9 months</td>
                    </tr>
                    <tr>
                        <td>YSC</td>
                        <td>When you close your browser</td>
                    </tr>
                    <tr>
                        <td>remote_sid</td>
                        <td>When you close your browser</td>
                    </tr>
                </tbody>
            </table>
            <p>
                Read{' '}
                <a
                    href="https://policies.google.com/privacy"
                    rel="noreferrer"
                    target="_blank"
                >
                    YouTube’s Privacy Policy
                </a>
                .
            </p>
        </Content>
    </div>
);

export default Cookies;
