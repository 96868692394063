import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Spinner } from '../../presentational';
import styles from './Busy.module.css';

const Busy: FunctionComponent = () => (
    <div className={styles.root}>
        <Helmet>
            <title>000-VAT Busy</title>
        </Helmet>
        <Spinner />
    </div>
);

export default Busy;
