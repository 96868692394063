import React, { FunctionComponent } from 'react';
import styles from './YoutubeVideo.module.css';

export interface YoutubeVideoProps {
    videoSrc?: string;
}

const YoutubeVideo: FunctionComponent<YoutubeVideoProps> = (
    props: YoutubeVideoProps
) => {
    const { videoSrc } = props;
    return (
        <div className={styles.root}>
            <iframe
                width="560"
                height="315"
                src={videoSrc}
                title="Youtube Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
        </div>
    );
};

export default YoutubeVideo;
