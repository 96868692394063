import { createEntityAdapter, createSlice, PayloadAction, EntityState } from '@reduxjs/toolkit';
import { Business } from './businesses.model';
import { fetchBusinessesThunk } from './businesses.thunks';

export interface BusinessesState extends EntityState<Business> {
    isBusy: boolean
}

export const businessesAdapter = createEntityAdapter<Business>({
    selectId: business => business.id,
    sortComparer: (a, b) => a.name.localeCompare(b.name)
});

export const businessesSlice = createSlice({
    name: 'businesses',
    initialState: { 
        ...businessesAdapter.getInitialState(),
        isBusy: false
    } as BusinessesState,
    reducers: {
        addBusiness: businessesAdapter.addOne,
        updateBusiness: businessesAdapter.updateOne,
        removeBusiness: businessesAdapter.removeOne,
        recieved(state, action: PayloadAction<{ businesses: Business[] }>) {
            businessesAdapter.setAll(state, action.payload.businesses)
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchBusinessesThunk.pending, (state, action) => ({
            ...state,
            isBusy: false
        }))
        builder.addCase(fetchBusinessesThunk.fulfilled, (state, action) => ({
            ...state,
            ...businessesAdapter.setAll(state, action.payload),
            isBusy: false
        }))
        builder.addCase(fetchBusinessesThunk.rejected, (state, action) => ({
            ...state,
            isBusy: false
        }))
    }
}); 

export const { addBusiness, updateBusiness, removeBusiness } = businessesSlice.actions;
