import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Redirect,
    Route,
} from 'react-router-dom';
import AnalyticsPageTracker from './analytics/components/AnalyticsPageTracker';
import {
    Busy,
    Cookies,
    Form,
    GetStarted,
    Home,
    Import,
    Privacy,
    SubmitAReturn,
    Terms,
} from './containers';
import { CookieNotice, PageFooter, ScrollToTop } from './presentational';
import './App.css';

function App() {
    return (
        <Router>
            <AnalyticsPageTracker />
            <ScrollToTop />
            <Switch>
                <Route exact path="/">
                    <Home />
                    <PageFooter />
                </Route>
                <Route path="/Form">
                    <Form />
                    <PageFooter />
                </Route>
                <Route path="/getstarted">
                    <GetStarted />
                    <PageFooter />
                </Route>
                <Route path="/submitareturn">
                    <SubmitAReturn />
                    <PageFooter />
                </Route>
                <Route path="/auth/callback">
                    <Busy />
                </Route>
                <Route path="/import">
                    <Import />
                    <PageFooter />
                </Route>
                <Route path="/privacy">
                    <Privacy />
                    <PageFooter />
                </Route>
                <Route path="/terms">
                    <Terms />
                    <PageFooter />
                </Route>
                <Route path="/cookies">
                    <Cookies />
                    <PageFooter />
                </Route>
                <Route path="/legal/privacy.html">
                    <Redirect to="/privacy" />
                </Route>
                <Route path="/legal/terms.html">
                    <Redirect to="/terms" />
                </Route>
            </Switch>
            <CookieNotice />
        </Router>
    );
}

export default App;
