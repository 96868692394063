import React, { FunctionComponent } from 'react';
import styles from './Content.module.css';

interface ContentProps {
    children: React.ReactNode;
}

const Content: FunctionComponent<ContentProps> = (props: ContentProps) => {
    const { children } = props;
    return (
        <div className={styles.wrapper}>
            <div className={styles.root}>{children}</div>
        </div>
    );
};

export default Content;
