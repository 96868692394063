import { useLocation } from 'react-router-dom';
import { FunctionComponent, useEffect } from 'react';
import app from 'firebase/app';
import 'firebase/analytics';

const AnalyticsPageTracker: FunctionComponent = () => {
    const location = useLocation();
    useEffect(() => {
        const pagePath = location.pathname + location.search;
        app.analytics().setCurrentScreen(pagePath);
        app.analytics().logEvent('page_view', { pagePath });
    }, [location]);
    return null;
};

export default AnalyticsPageTracker;
